import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SpatialNavigation, {
  Focusable,
  FocusableSection,
} from "react-js-spatial-navigation";
import Navbar from "../../Components/layout/Navbar/Navbar";
import Image from "../../Components/commons/Image/Image";
import placeholderImage from "../../Assets/images/placeholder.png";
import "./Homepage.css";

const Homepage = () => {
  const navigate = useNavigate();
  const [channels, setChannels] = useState([]);
 // Function to cache images locally
 const cacheImages = (channels) => {
  channels.forEach((channelItem) => {
    const img = new Image();
    img.src = channelItem.logo;
  });
};
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch('https://iptv-backend-1lv3.onrender.com/api/tv/channels');
        if (!response.ok) {
          throw new Error('Failed to fetch channels');
        }
        const data = await response.json();
        console.log("count",data.count)

        // Set the channels state immediately after fetching the data
        setChannels(data.channels);
  
        // Cache images locally
        cacheImages(data.channels);
        
        // Store the fetched data in localStorage along with a timestamp
        localStorage.setItem('channelsData', JSON.stringify({
          timestamp: new Date().getTime(),
          data: data.channels,
        }));
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };
  
    fetchChannels();
  }, []);
  
 

  const filterHandler = (val) => {
    console.log("val", val);
    // Filtering logic can be implemented here if needed
  };

  return (
    <div className="homepageWrapper">
      <SpatialNavigation>
        <Navbar filterHandler={filterHandler} />
        <section className="contentWrapper">
          <div className="channelListWrapper">
            <ul className="channelList">
              <FocusableSection>
                {/* Filter options can be added here if needed */}
              </FocusableSection>
            </ul>
          </div>
          <div className="channelsView">
            {channels.length ? (
              channels.map((channelItem, index) => (
                <Focusable
                  key={index}
                  onClickEnter={() => {
                    navigate(
                      `/channel?channelUrl=${encodeURIComponent(
                        channelItem.url
                      )}`
                    );
                  }}
                >
                  <span
                    className="channelItem"
                    title={channelItem.id}
                    onClick={() => {
                      navigate(
                        `/channel?channelUrl=${encodeURIComponent(
                          channelItem.url
                        )}`
                      );
                    }}
                  >
                    <Image
                      url={channelItem.logo}
                      altUrl={placeholderImage}
                      alt="channelImage"
                      placeholderImage={placeholderImage}
                    />
                    <text className="channelName">{channelItem.name}</text>
                  </span>
                </Focusable>
              ))
            ) : (
              <div className="noResults">Sorry, No Results Found!</div>
            )}
          </div>
        </section>
      </SpatialNavigation>
    </div>
  );
};

export default Homepage;
